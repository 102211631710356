import React, {useState} from "react";
import Nav from "./Nav";
import SubNav from "./SubNav";
import TopNav from "./TopNav";
import {Link} from "gatsby";
import logo from "../assets/img/Logo.png";

export default function SideBar({sections = []}) {
    const [headerOpen, toggleHeader] = useState(false);
    return (
        <>
            <header id="header">
                <div className="inner">
                    <Link to="/" className="logo">
                        <span className="title"><img style={{width: 180}} src={logo} alt=""/></span>
                    </Link>
                    <TopNav onMenuClick={() => toggleHeader(!headerOpen)}/>
                </div>
            </header>
            <div className={`${headerOpen ? "is-menu-visible" : " "}`}>
                <Nav onClose={() => toggleHeader(!headerOpen)}/>
            </div>
        </>
    );
}
